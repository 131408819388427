import axios from 'axios';

// Define the payload type
interface PostCardEmailData {
    to: string | null;
    uuid: string | null;
    imagePostcardFront: string | undefined;
}

// Service function to call the backend API
const sendEmail = async (data: PostCardEmailData) => {
    if (!data) {
        throw new Error('No image provided');
    }
    try {
        axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'https://ai-postcard-api.dev-scapp.swisscom.com';
        console.log(data);
        const response = await axios.post('/send-email', {
            to: data.to,
            uuid: data.uuid,
            imagePostcardFront: data.imagePostcardFront,
        });
        return response.data;
    } catch (error) {
        console.error('Error generating post card:', error);
        throw error;
    }
};

export default sendEmail;
