import axios from 'axios';

// Define the payload type
interface PostCardData {
    id: number;
    base64_image: string | undefined;
}

// Service function to call the backend API
const generatePostCard = async (data: PostCardData) => {
    if (!data.base64_image) {
        throw new Error('No image provided');
    }
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL || 'https://ai-postcard-api.dev-scapp.swisscom.com',
        });
        console.log(api.defaults.baseURL);

        const dataSend = {
            id: data.id,
            base64_image: data.base64_image.split(',')[1],
        };
        console.log(dataSend);

        const response = await api.post('/swisscom-backend', dataSend, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        throw error;
    }
};


export default generatePostCard;
