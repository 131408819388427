import React, {useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';

interface EmailSentProps {
    emailSent: boolean;
    handleEmail: (event: any) => void;
    sendEmailPostcard: () => void;
    email: string;
}

const EmailSent: React.FC<EmailSentProps> = ({emailSent, handleEmail, sendEmailPostcard, email}) => {
    const navigate = useNavigate();

    useEffect(() => {

        let timer: number;
        if (emailSent) {
            document.querySelector('sdx-header')?.showToast({
                description: "The page will refresh in 15 seconds",
            });
            timer = window.setTimeout(() => {
                navigate("/");
            }, 15000); // 15s timeout
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [emailSent]);

    return (
        <>
            {!emailSent ? (
                <>
                    <div className="margin-v-4">
                        <h2 style={{textAlign: "center"}}>Type in an email address to send your AI Selfie</h2>
                    </div>
                    <div className="sdx-input-textarea">
                        <sdx-input
                            label="Enter your email"
                            placeholder="Please write your input here"
                            type="email"
                            onInput={handleEmail}
                        ></sdx-input>
                    </div>
                    <div className="padding-v-4">
                        <sdx-button-group layout="responsive-center">
                            <sdx-button
                                theme={email ? "confirm" : "secondary"}
                                label="Send"
                                disabled={!email}
                                onClick={email ? sendEmailPostcard : undefined}
                            ></sdx-button>
                        </sdx-button-group>
                    </div>
                    <div className="padding-bottom-4">
                        <Link style={{display: "contents"}} to="/">
                            <sdx-button-group layout="responsive-center">
                                <sdx-button label="Go to Discovery Page"></sdx-button>
                            </sdx-button-group>
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="col text-align-center">
                        <div className="padding-v-4">
                            <h2 style={{textAlign: "center"}}>Your AI Selfie was sent. <br/>
                                Enjoy your stay!
                            </h2>
                        </div>
                        <img
                            src="/img/email/bird.png"
                            alt="Email sent confirmation"
                        />
                    </div>
                    <div className="margin-bottom-4">
                        <Link style={{display: "contents"}} to="/">
                            <sdx-button-group layout="responsive-center">
                                <sdx-button label="Go to Start Page"></sdx-button>
                            </sdx-button-group>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};

export default EmailSent;