import React, {useEffect} from 'react';
import generatePostCard from '../../services/PostCardGenerator';
import {useNavigate} from "react-router-dom";
import {TimeoutError} from "../../utils/TimeoutError";

interface SpinnerProps {
    imageBase64: string | undefined;
    sportsId: number | null;
    generatedPostCard: (postCard: BackendResponse) => void;
    active: boolean;
}

export interface BackendResponse {
    uuid: string;
    base64String: string;
}

const Spinner: React.FC<SpinnerProps> = ({imageBase64, sportsId, generatedPostCard, active}) => {
    const navigate = useNavigate();

    // Use useEffect to start the progress when the component mounts
    useEffect(() => {
        if (!active) {
            return
        }

        const postCardData = {
            id: sportsId!, // Replace with actual sport number
            base64_image: imageBase64, // Replace with actual base64 image data
        };

        // Create a promise that rejects after 60 seconds
        const timeout = new Promise((_, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(new TimeoutError("Generation PostCard time out"));
            }, 60000);
        });

        // Race the generatePostCard promise against the timeout
        Promise.race([
            generatePostCard(postCardData) as Promise<BackendResponse>,
            timeout
        ])
            // Call the service function to generate the post card
            .then((data) => {
                const backendData = data as BackendResponse;
                console.log('Post card generated:', data);
                generatedPostCard(backendData);
            })
            .catch(error => {
                console.error('Error in post card generation:', error);

                let errorMessage = 'Oops! Something went wrong: \nThe page will refresh!';
                if (error instanceof TimeoutError) {
                    errorMessage = 'Operation timed out. \nPlease try again.';
                }

                document.querySelector('sdx-header')?.showToast({
                    description: errorMessage,
                    type: 'warning'
                })

                // Add a 3-second timer before navigating
                setTimeout(() => {
                    navigate("/");
                }, 5000);
            });
    }, [active])
    ; // Empty dependency array to run effect once when component mounts

    return (
        <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
    );
};

export default Spinner;