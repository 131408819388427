import React, {useState, useEffect, useRef} from 'react';
import WebcamComponent from "./PostCardWebcam/WebcamComponent";

interface WebcamWrapperProps {
    screenshot: string | undefined,
    handleScreenshot: (newScreenshot: string | undefined) => void,
    webcamActive: boolean,
    setWebcamActive: (active: boolean) => void;
    activateProgressBarOnClickNext: () => void;
}

const WebcamWrapper: React.FC<WebcamWrapperProps> = ({
                                                         screenshot,
                                                         handleScreenshot,
                                                         webcamActive,
                                                         setWebcamActive,
                                                         activateProgressBarOnClickNext
                                                     }) => {
    // Timer state
    const [timer, setTimer] = useState(5);
    const timerRef = useRef<NodeJS.Timeout>();
    const [timerActive, setTimerActive] = useState(false);

    // // Start timer when screenshot is taken
    useEffect(() => {
        if (screenshot && !timerActive) {
            setTimerActive(true);
            setTimer(5);
        }
    }, [screenshot]);
    //
    // // Timer countdown effect
    // useEffect(() => {
    //     if (timerActive && timer > 0) {
    //         timerRef.current = window.setTimeout(() => {
    //             setTimer((prevTimer) => prevTimer - 1);
    //         }, 1000);
    //     } else if (timer === 0 && !hasExecutedRef.current) {
    //         hasExecutedRef.current = true;
    //         setWebcamActive(false);
    //         activateProgressBarOnClickNext();
    //         setTimerActive(false);
    //         console.log("pass")
    //     }
    //
    //     return () => {
    //         if (timerRef.current) {
    //             window.clearTimeout(timerRef.current);
    //         }
    //     };
    // }, [timerActive, timer]);
    //
    // // Reset the execution flag when the timer starts again
    // useEffect(() => {
    //     if (timerActive) {
    //         hasExecutedRef.current = false;
    //     }
    // }, [timerActive]);

    // useEffect(() => {
    //     if (!screenshot) return;
    //     if (timerRef.current) window.clearInterval(timerRef.current);
    //     timerRef.current = window.setInterval(() => {
    //         setTimer((prevTimer) => {
    //             const newTimer = prevTimer - 1;
    //             if (newTimer <= 0) {
    //                 setWebcamActive(false);
    //                 activateProgressBarOnClickNext();
    //                 window.clearInterval(timerRef.current);
    //                 console.log("pass");
    //             }
    //             return newTimer;
    //         });
    //     }, 1000);
    //     return () => {
    //         if (timerRef.current) {
    //             window.clearTimeout(timerRef.current);
    //         }
    //     }
    // }, [screenshot]);

    useEffect(() => {
        if (!timerActive) return;
        if (timer > 0) {
            setTimeout(() => setTimer(timer - 1), 1000);
        }
        if (timer === 0) {
            setWebcamActive(false);
            activateProgressBarOnClickNext();
            setTimerActive(false);
            console.log("pass");
        }
    }, [timer, timerActive]);


    const handleNext = (): void => {
        setWebcamActive(false);
        activateProgressBarOnClickNext();
        setTimerActive(false);
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
    };

    const handleBack = (): void => {
        document.querySelectorAll('sdx-progress-full')[0].previousStep();
        setWebcamActive(false);
        setTimerActive(false);
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
    };

    return (
        <>
            <div className="col-xs-12">
                <WebcamComponent
                    screenshot={screenshot}
                    onScreenshot={handleScreenshot}
                    disable={!webcamActive}
                ></WebcamComponent>
            </div>

            <div className="flex flex-col items-center gap-2">
                {screenshot && (
                    <div className="text-sm text-gray-600">
                        Auto-proceeding in {timer} seconds...
                    </div>
                )}
            </div>

            <div className="col-xs-12">
                <sdx-button-group layout="responsive-center">
                    <sdx-button label="Next"
                                disabled={!screenshot}
                                onClick={handleNext}
                    >
                    </sdx-button>
                    <sdx-button label="Back"
                                theme="secondary"
                                onClick={handleBack}
                    >
                    </sdx-button>
                </sdx-button-group>
            </div>
        </>
    );
}

export default WebcamWrapper;