import React from 'react';
import {Link} from 'react-router-dom';

const LandingPage: React.FC = () => {

    return (
        <div className="container"> {/* .container is an SDX class */}
            <div className="margin-v-3">
                <div className="row"
                     style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                    <div className="col-xs-2" style={{display: 'flex', justifyContent: 'center'}}>
                        <img src="/img/logo/SC_Lifeform_GIF_small.gif" alt="swisscom logo"/>
                    </div>
                    <div className="col-xs-10">
                        <h1 style={{marginBottom: "0"}}>Swisscom</h1>
                    </div>
                </div>
            </div>

            <h1 className="hero"
                style={{textAlign: "center"}}
            >
                Take your AI Selfie
            </h1>
            <div className="row flex-items-xs-center">
                <div className="col-md-8">
                    <p style={{textAlign: "center"}}>
                        Experience our cutting-edge AI Selfie app: capture your selfie, get your unique AI avatar,
                        and instantly send it to your email address - for an unforgettable digital experience. <br/>
                        Your avatar will be created with AI capabilities based on our Swiss AI Platform.
                        Please consider that this is GenAI generated and the pictures therefore might contain mistakes
                        or might lack in realistic accuracy
                    </p>
                </div>
            </div>
            <div className="margin-v-4">
                <Link style={{display: "contents"}} to="/steps">
                    <sdx-button-group layout="responsive-center">
                        <sdx-button label="Start"></sdx-button>
                    </sdx-button-group>
                </Link>
            </div>

            <div className="margin-v-4">
                <div className="row">
                    <img src="/img/landscape/Illustration-121.svg" alt="welcome people"/>
                </div>
            </div>
            <footer style={{
                backgroundColor: "#f8f8f8",
                borderTop: "1px solid #ddd",
                color: "#666",
                fontSize: "0.8rem",
                padding: "1rem",
                textAlign: "center",
                marginTop: "2rem",
            }}>
                <p style={{
                    marginTop: "0",
                }}>
                    <span style={{fontWeight: "bold"}}>Disclaimer</span>: All data is processed in Switzerland by
                    Swisscom and will be deleted after the event.
                    The data will not be shared with any third party.
                </p>
            </footer>

        </div>
    )
}

export default LandingPage;