import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import sendEmail from "../services/PostCardEmail";
import './Stepper.css';
import Spinner from "../components/Spinner/Spinner";
import EmailSent from "../components/EmailSent/EmailSent";
import WebcamWrapper from "../components/WebcamWrapper";
import {BackendResponse} from "../components/Spinner/Spinner";


const Stepper: React.FC = () => {

    const navigate = useNavigate();

    const [sportsId, setSportsId] = useState<number | null>(null);
    const [webcamActive, setWebcamActive] = useState(false);
    const [screenshot, setScreenshot] = useState<string>();
    const [isProgressActive, setIsProgressActive] = useState(false);
    const [email, setEmail] = useState("");
    const [generatedPostCard, setGeneratedPostCard] = useState<string>();
    const [emailSent, setEmailSent] = useState(false);
    const [uuid, setUUID] = useState<string | null>(null);
    const [emailError, setEmailError] = useState(false);


    useEffect(() => {
        // Center the SDX Progress
        const intervalId = setInterval(() => {
            const progressBar = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div") as HTMLElement;

            if (progressBar) {
                progressBar.style.justifyContent = "center"; // Center the progress bar
                clearInterval(intervalId);
            }
        }, 50);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (!emailError) return
        let timer: number;
        document.querySelector('sdx-header')?.showToast({
            description: "Something went wrong when the email was sent.. The page will refresh",
            type: 'warning'
        });
        timer = window.setTimeout(() => {
            navigate("/");
        }, 6000); // 5s timeout
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [emailError]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const button1 = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div > sdx-progress-full-step:nth-child(1)")
                ?.shadowRoot?.querySelector("div > button") as HTMLButtonElement;
            const button2 = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div > sdx-progress-full-step:nth-child(2)")
                ?.shadowRoot?.querySelector("div > button") as HTMLButtonElement;
            const button3 = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div > sdx-progress-full-step:nth-child(3)")
                ?.shadowRoot?.querySelector("div > button") as HTMLButtonElement;
            const button4 = document
                .querySelector("#root > div > sdx-progress-full")
                ?.shadowRoot?.querySelector("nav > sdx-scroll-view > div > sdx-progress-full-step:nth-child(4)")
                ?.shadowRoot?.querySelector("div > button") as HTMLButtonElement;
            if (button1 && button2 && button3 && button4) {
                button1.style.pointerEvents = "none";
                button2.style.pointerEvents = "none";
                button3.style.pointerEvents = "none";
                button4.style.pointerEvents = "none";
                clearInterval(intervalId);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    const handleCardClick = (id: number) => {
        setSportsId(id);
        setWebcamActive(true);
        document.querySelectorAll('sdx-progress-full')[0].nextStep();
    };

    const handleScreenshot = (newScreenshot: string | undefined) => {
        setScreenshot(newScreenshot);
    };

    const activateProgressBarOnClickNext = () => {
        setIsProgressActive(true);
        document.querySelectorAll('sdx-progress-full')[0].nextStep();
    };

    const handleGeneratedPostCard = (postCard: BackendResponse) => {
        setGeneratedPostCard(postCard.base64String);
        setUUID(postCard.uuid);
        setIsProgressActive(false);
        document.querySelectorAll('sdx-progress-full')[0].nextStep();
    }

    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    }

    const sendEmailPostcard = async () => {
        if (!email || !generatedPostCard || !uuid) {
            throw new Error("Missing one or more of the required fields: email, generated postcard, postcard back image");
        }

        try {
            const response = await sendEmail({
                to: email,
                uuid: uuid,
                imagePostcardFront: generatedPostCard,
            });
            console.log("Email sent successfully", response);
            setEmailSent(true);
        } catch (error) {
            console.error("Failed to send email:", error);
            setEmailError(true);
        }
    }


    return (
        <div className="container"> {/* .container is an SDX class */}
            <div className="margin-v-3">
                <div className="row"
                     style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                    <div className="col-xs-2" style={{display: "flex", justifyContent: "center"}}>
                        <img src="/img/logo/SC_Lifeform_GIF_small.gif" alt="swisscom logo"/>
                    </div>
                    <div className="col-xs-10">
                        <h1 style={{marginBottom: "0"}}>Swisscom</h1>
                    </div>
                </div>
            </div>


            <sdx-progress-full
                value={1}
                theme="responsive"
            >
                <sdx-progress-full-step label="Select Theme">
                    <div className="row row--gutters">
                        <div className="col-lg-4">
                            <sdx-card label="Rafting" className="hydrated" onClick={() => handleCardClick(0)}
                                      background={sportsId === 0 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/rafting.png" alt="rafting" className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                        <div className="col-lg-4">
                            <sdx-card label="Climbing" className="hydrated" onClick={() => handleCardClick(1)}
                                      background={sportsId === 1 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/climbing_3.png" alt="climbing" className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                        <div className="col-lg-4">
                            <sdx-card label="Skiing" className="hydrated" onClick={() => handleCardClick(2)}
                                      background={sportsId === 2 ? "grey" : undefined}>
                                <div className="sdx-card-image">
                                    <img src="/img/card/skiing.png" alt="skiing"
                                         className="card-image"/>
                                </div>
                            </sdx-card>
                        </div>
                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Take Picture">
                    <div className="row row--gutters">

                        <WebcamWrapper screenshot={screenshot} handleScreenshot={handleScreenshot}
                                       webcamActive={webcamActive} setWebcamActive={setWebcamActive}
                                       activateProgressBarOnClickNext={activateProgressBarOnClickNext}>
                        </WebcamWrapper>

                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Generate">
                    <div className="row flex-items-xs-middle" style={{justifyContent: "center"}}>
                        <div className="col margin-v-2">
                            <h3 style={{textAlign: "center"}}>Please wait.
                                We are generating your AI Selfie.</h3>
                        </div>
                        <Spinner
                            imageBase64={screenshot}
                            sportsId={sportsId}
                            generatedPostCard={handleGeneratedPostCard}
                            active={isProgressActive}
                        />
                    </div>
                </sdx-progress-full-step>


                <sdx-progress-full-step label="Your AI Selfie">
                    <h1 style={{textAlign: "center"}}>Your AI Selfie is ready!</h1>
                    <div className="row flex-items-xs-center">
                        {generatedPostCard &&
                            <img className="generated-image" src={`data:image/png;base64,${generatedPostCard}`}
                                 alt="Postcard"/>}
                    </div>
                    <EmailSent emailSent={emailSent} handleEmail={handleEmail} sendEmailPostcard={sendEmailPostcard}
                               email={email}>
                    </EmailSent>
                </sdx-progress-full-step>
            </sdx-progress-full>
        </div>
    );
}

export default Stepper;
