import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import Stepper from "./pages/Stepper";
import LandingPage from "./pages/LandingPage";

const App: React.FC = () => {

    useEffect(() => {
        // Center the SDX Progress
        const intervalId = setInterval(() => {
            const sdxHeader = document.querySelector("#root > sdx-header") as HTMLElement;
            const sdxWrapperMain = document.querySelector("#root > sdx-header")?.shadowRoot?.querySelector("header > div.header > div.wrapper.main") as HTMLElement;

            if (sdxHeader && sdxWrapperMain) {
                sdxHeader.style.height = "0"; // Center the progress bar
                sdxWrapperMain.style.height = "0";
                clearInterval(intervalId);
            }
        }, 50);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <sdx-header
            ></sdx-header>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/*" element={<LandingPage/>}/>
                <Route path="/steps" element={<Stepper/>}/>
            </Routes>
        </>
    );
}

export default App;
